import React from 'react'
import Modal from 'react-bootstrap4-modal';
import { withRouter } from 'react-router-dom';
import Loader from '../common/Loader';

function Actions (props) {
  return <React.Fragment>
    <button className={"btn btn-md btn-warning"} onClick={props.handleEdit}>Edit</button>
  </React.Fragment>
}

class Languages extends React.Component {
  state = {
    loading: true,
    data: [],
    searchQuery: "",
    id: 0,
    value: "",
  }

  handleInputChange = value => {
    this.setState({ value });
  };

  componentDidMount() {
    this._loadData();
  }

  _loadData = () => {
    this.setState({ loading: true });
    Promise.all([
      this.props.adminService.getAllLanguages(),
    ]).then(([languages]) => {
      if (languages) {
        this.setState({ data: languages.data, loading: false });
      }
    })
  }

  handleSubmit = () => {
    this.props.adminService.createAllLanguages([{
      id: this.state.id,
      value: this.state.value
    }]).then((result) => {
      if (result && result.data) {
        this._loadData();
        this.setState({ open: false, opened: false })
      }
    }).catch((e) => {
      alert(e);
    });
  };

  _filterData = (obj) => {
    return obj.value.toLowerCase().includes(this.state.searchQuery.toLowerCase())
  }

  _closeModal() {
    this.setState({ 
      open: false, 
      opened: false,
    });
  }

  _openModal = () => {
    this.setState({ 
      open: true,
      id: 0,
      value: "",
    });
  }

  handleEdit = ({id, value}) => {
    this.setState({
      open: true,
      id,
      value
    })
  }

  render() {
    return <React.Fragment>
      <Modal visible={this.state.open} dialogClassName={'modal-md'} onClickBackdrop={this._closeModal.bind(this)}>
        <div className="modal-header">
          <button className={"btn btn-md btn-success"} onClick={this.handleSubmit}>{this.state.id ? "Update" : "Save"}</button>
          <button className={'btn btn-default'} onClick={this._closeModal.bind(this)}>x</button>
        </div>
        <div className="modal-body">
        <div className={'row'}>
            <div className={'col-sm-12'}>
                <div className="input-group input-group-sm" style={{margin: '10px'}}>
                    <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">Language</span>
                    </div>
                    <input type="text" value={this.state.value} onChange={(e) => this.handleInputChange(e.target.value)} className="form-control" />
                </div>
            </div>
          </div>
        </div>
      </Modal>
      <nav className="navbar navbar-light bg-light">
        <button className={"btn btn-md btn-success"} onClick={this._openModal}>Create</button>
        <div className="form-group">
          <div className="input-group">
            <input
              className="form-control"
              value={this.state.searchQuery}
              onChange={(event) => this.setState({ searchQuery: event.target.value })}
              placeholder="Search by name or title"
            />
          </div>
        </div>
      </nav>
      {this.state.loading ? <Loader /> :
      (<table className={"table table-hover"}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Value</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.filter(this._filterData).map(o => <tr key={o.id}>
            <td>{o.id}</td>
            <td>{o.value}</td>
            <td><Actions handleEdit={() => this.handleEdit(o)}/></td>
          </tr>)}
        </tbody>
      </table>)}
      </React.Fragment>
  }
}

export default withRouter(Languages);