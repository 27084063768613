
import React from 'react'
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
`;

export default function Loader () {
    return <ClipLoader
    size={50}
    css={override}
    color={"#123abc"}
    loading={true}
  />
}