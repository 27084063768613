import React from 'react';
import './Admin.css'
import FormBuilderView from './FormBuilderView';
import {
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import TranslationTableView from './TranslationTableView';
import MapLogicView from './MapLogicView';
import TriggersView from './TriggersView';
import Languages from './Languages';
import ScoreLogic from './ScoreLogic';
import AdminService from '../services/admin-service';

class AdminView extends React.Component {

  state = {
    adminToken: '',
    adminService: null,
    loading: true,
  }

  componentDidMount() {
    const adminService = AdminService(this.state.adminToken);
    this.setState({
      adminService,
      loading: false,
    })
  }
  
  handleInputChange = (e) => {
    const adminService = AdminService(e.target.value);
    this.setState({
      adminToken: e.target.value,
      adminService
    });
  }

  render() {
    return <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <NavLink activeClassName='active' className={'nav-link'} to="/build">Build Questionnaire</NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName='active' className={'nav-link'} to="/translations">Translations</NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName='active' className={'nav-link'} to="/score-logic">Score Logic</NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName='active' className={'nav-link'} to="/map-score-logic">Map Logic</NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName='active' className={'nav-link'} to="/triggers">Triggers</NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName='active' className={'nav-link'} to="/languages">Languages</NavLink>
          </li>
        </ul>
      </nav>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-sm-6'}>
            <div className="input-group input-group-sm" style={{margin: '10px'}}>
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">Token</span>
              </div>
              <input type="password"  value={this.state.adminToken} onChange={this.handleInputChange} className="form-control" />
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-sm-12'}>
            {this.state.loading || !this.state.adminToken ? null : 
            <Switch>
              <Route path={`/build`}>
                <FormBuilderView adminService={this.state.adminService} adminToken={this.state.adminToken} />
              </Route>
              <Route path={`/translations`}>
                <TranslationTableView adminService={this.state.adminService} adminToken={this.state.adminToken} />
              </Route>
              <Route path={`/score-logic`}>
                <ScoreLogic adminService={this.state.adminService} adminToken={this.state.adminToken} />
              </Route>
              <Route path={`/map-score-logic`}>
                <MapLogicView adminService={this.state.adminService} adminToken={this.state.adminToken} />
              </Route>
              <Route path={`/triggers`}>
                <TriggersView adminService={this.state.adminService} adminToken={this.state.adminToken} />
              </Route>
              <Route path={`/languages`}>
                <Languages adminService={this.state.adminService} adminToken={this.state.adminToken} />
              </Route>
            </Switch>}
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}

export default AdminView;
