import React from 'react'
import FormContainer from './FormContainer'
import CommonSevice from './../services/common-service'
import store from 'store2'
import config from '../config'
import { withRouter } from 'react-router-dom'
import { getLangName } from '../services/common-util'
import Loader from '../common/Loader'

class FormView extends React.Component {

  state = {
    commonService: null,
    loading: true,
    form: {},
    translations: {},
    lang: 'en',
    title: '',
    scoreQMapId: null,
    apiFail: false,
  }

  handleInput = event => {
    this.setState({ form: event.target.value });
  };

  componentDidMount() {
    this.setState({ loading: true });
    const search = this.props.location.search;
    const token = new URLSearchParams(search).get("o");
    const scoreQMapId = new URLSearchParams(search).get("q");
    const lang = new URLSearchParams(search).get("l") || this.state.lang;
    store.set(config.tokenKey, token);
    const commonService = CommonSevice(token);
    // commonService.removeSensitiveParams();
    commonService.fetchQuestionnaire(scoreQMapId).then(({ data } = {}) => {
      if(data && data.jsonSchema) {
        const langName = getLangName(lang);
        const codes = commonService.extractCode(data.jsonSchema);
        if(data.title) codes.push(data.title);
        commonService.fetchTranslations(codes, langName).then((translations) => {
          if(translations){
            // if(Object.keys(translations).length !== codes.length && lang !== 'en') {
              // commonService.fetchTranslations(codes, 'en').then((translationsEn) => {
              //   this.setState({ form : data.jsonSchema, title: translationsEn[data.title] ? translationsEn[data.title] : data.title, loading: false, lang, translations: translationsEn });
              // })
            // } else {
              this.setState({ form : data.jsonSchema, title: translations[data.title] ? translations[data.title] : data.title, loading: false, lang, translations, scoreQMapId });
            // }
          } else {
            this.setState({ apiFail: true });
          }
        });
      } else {
        this.setState({ apiFail: true })
      }
    });
  }

  render() {
    return (<div className="App">
      <div className="container">
        <div className='row'>
          <div className='col-sm-12 col-md-8 offset-md-2 editor'>
            <header className="App-header">
              <h6>
                {this.state.title}
              </h6>
            </header>
          </div>
        </div>
      </div>
      <div className="container">
        <div className='row'>
          <div className='col-sm-12 col-md-8 offset-md-2 editor'>
            { this.state.apiFail ? <h3>Sorry! Your connection failed or token has expired. Please try again.</h3> : null }
            {this.state.loading && !this.state.apiFail ? <Loader/> : (<FormContainer 
            translations={this.state.translations} 
            form={this.state.form} 
            scoreQMapId={this.state.scoreQMapId}
            lang={this.state.lang}
            />) }
          </div>
        </div>
      </div>
      <div style={{width: '100%', height: '500px'}}>

      </div>
    </div>)
  }
}

export default withRouter(FormView);
