import React from 'react'
import Modal from 'react-bootstrap4-modal';
import { withRouter } from 'react-router-dom';
import Loader from '../common/Loader';
import Select from 'react-select';

function Actions (props) {
  return <React.Fragment>
    <button className={"btn btn-md btn-danger"} onClick={props.handleDelete}>x</button>
  </React.Fragment>
}

class TriggersView extends React.Component {
  state = {
    loading: true,
    form: {},
    data: [],
    scoreQMaps: [],
    actions: [],
    outcomes: [],
    searchQuery: "",
    scoreQMapId: "",
    actionId: 0,
    outcomeId: 0,
    actionParams: "",
  }

  handleInput = event => {
    this.setState({ form: event.target.value });
  };

  componentDidMount() {
    this._loadData();
  }

  _loadData = () => {
    this.setState({ loading: true });
    Promise.all([
      this.props.adminService.getTriggers(),
      this.props.adminService.getScoreQMap(),
      this.props.adminService.getActions(),
      this.props.adminService.getOutcomes(),
    ]).then(([triggers, scoreQMaps, actions, outcomes]) => {
      if (triggers) {
        triggers.data.forEach(trigger => {
          trigger.scoreQMap = scoreQMaps.data.find(({id}) => id === trigger.scoreQMapId)
          trigger.outcome = outcomes.data.find(({id}) => id === trigger.outcomeId)
          trigger.action = actions.data.find(({id}) => id === trigger.actionId)
        });
        this.setState({ data: triggers.data, loading: false, scoreQMaps: scoreQMaps.data, actions: actions.data, outcomes: outcomes.data });
      }
    })
  }

  handleSubmit = () => {
    this.props.adminService.createTriggers([{
      scoreQMapId: this.state.scoreQMapId,
      actionId: this.state.actionId,
      outcomeId: this.state.outcomeId,
      actionParams: this.state.actionParams,
    }]).then((result) => {
      if (result && result.data) {
        this._loadData();
        this.setState({ open: false, opened: false })
      }
    }).catch((e) => {
      alert(e);
    });
  };

  handleDelete = (o) => {
    const cnf = window.confirm('Are you sure you want to delete this mapping?');
    if (cnf) {
      this.props.adminService.deleteTrigger(o.id).then((result) => {
        if (result && result.data) {
          this._loadData();
          this.setState({ open: false, opened: false })
        }
      }).catch((e) => {
        alert(e);
      });
    }
  }

  _filterData = (obj) => {
    return obj.scoreQMap.slug.toLowerCase().includes(this.state.searchQuery.toLowerCase())
      || obj.outcome.outcomeName.toLowerCase().includes(this.state.searchQuery.toLowerCase())
      || obj.action.actionName.toLowerCase().includes(this.state.searchQuery.toLowerCase());
  }

  _closeModal() {
    this.setState({ 
      open: false, 
      opened: false,
    });
  }

  _openModal = () => {
    this.setState({ 
      open: true,
      scoreQMapId: "",
      actionId: 0,
      outcomeId: 0,
      actionParams: "",
    });
  }

  render() {
    return <React.Fragment>
      <Modal visible={this.state.open} dialogClassName={'modal-md'} onClickBackdrop={this._closeModal.bind(this)}>
        <div className="modal-header">
          <button className={"btn btn-md btn-success"} onClick={this.handleSubmit}>{this.state.id ? "Update" : "Save"}</button>
          <button className={'btn btn-default'} onClick={this._closeModal.bind(this)}>x</button>
        </div>
        <div className="modal-body">
        <div className={'row'}>
            <div className={'col-sm-12'}>
              <div className="form-group">
                <label>Question Score Logic Map</label>
                <Select
                  defaultValue={null}
                  options={this.state.scoreQMaps}
                  isClearable={true}
                  value={this.state.scoreQMaps.find(opt => this.state.scoreQMapId && opt.id === this.state.scoreQMapId)}
                  getOptionLabel={group => group.slug}
                  getOptionValue={group => group.id}
                  onChange={(scoreQMap) => {
                    this.setState({ scoreQMapId: scoreQMap.id });
                  }}
                />
              </div>
              <div className="form-group">
                <label>Action</label>
                <Select
                  defaultValue={null}
                  options={this.state.actions}
                  isClearable={true}
                  value={this.state.actions.find(opt => this.state.actionId && opt.id === this.state.actionId)}
                  getOptionLabel={action => action.actionName}
                  getOptionValue={action => action.id}
                  onChange={(action) => {
                    this.setState({ actionId: action.id });
                  }}
                />
              </div>
              <div className="form-group">
                <label>Outcome</label>
                <Select
                  defaultValue={null}
                  options={this.state.outcomes}
                  isClearable={true}
                  value={this.state.outcomes.find(opt => this.state.outcomeId && opt.id === this.state.outcomeId)}
                  getOptionLabel={outcome => outcome.outcomeName}
                  getOptionValue={outcome => outcome.id}
                  onChange={(outcome) => {
                    this.setState({ outcomeId: outcome.id });
                  }}
                />
              <div className="form-group">
                <label>Action Parameters</label>
                <div className="input-group">
                  <textarea
                    className="form-control"
                    value={this.state.actionParams}
                    onChange={(event) => this.setState({ actionParams: event.target.value })}
                    placeholder="Enter Action parameters as json"
                    rows={15}
                  />
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <nav className="navbar navbar-light bg-light">
        <button className={"btn btn-md btn-success"} onClick={this._openModal}>Create</button>
        <div className="form-group">
          <div className="input-group">
            <input
              className="form-control"
              value={this.state.searchQuery}
              onChange={(event) => this.setState({ searchQuery: event.target.value })}
              placeholder="Search by name or title"
            />
          </div>
        </div>
      </nav>
      {this.state.loading ? <Loader /> :
      (<table className={"table table-hover"}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Score-Logic Map</th>
            <th>Outcome</th>
            <th>Action</th>
            <th>Action Params</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.filter(this._filterData).map(o => <tr key={o.id}>
            <td>{o.id}</td>
            <td>{o.scoreQMap.slug}</td>
            <td>{o.outcome.outcomeName}</td>  
            <td>{o.action.actionName}</td>  
            <td><code>{o.actionParams}</code></td>
            <td>{new Date(o.createdAt * 1000).toUTCString()}</td>
            <td><Actions handleEdit={() => this.handleEdit(o)} handleDelete={() => this.handleDelete(o)}/></td>
          </tr>)}
        </tbody>
      </table>)}
      </React.Fragment>
  }
}

export default withRouter(TriggersView);
