import React from 'react'
import Modal from 'react-bootstrap4-modal';
import { FormBuilder } from 'react-formio/lib/components'
import { withRouter } from 'react-router-dom';
import Loader from '../common/Loader';

function Actions (props) {
  return <React.Fragment>
    <button className={"btn btn-md btn-success"} onClick={props.handleCopy}>Copy</button>
  </React.Fragment>
}

const builderOptions = {
  builder: {
    basic: false,
    advanced: false,
    data: false,
    layout: false,
    premium: false,
    customBasic: {
      title: 'Basic Components',
      default: true,
      weight: 0,
      components: {
        customRadio: {
          title: 'Radio',
          icon: 'dot-circle-o',
          schema: {
            label: 'Question code from translations table',
            type: 'radio',
            key: 'firstName',
            storageType: 'number'
          },
        },
        customHtml: {
          title: 'HTML Element',
          icon: 'code',
          schema: {
            label: 'Question code from translations table',
            type: 'htmlelement',
          },
        },
      }
    },
  }
}

const defaultSchema = {display: 'form'};

class FormBuilderView extends React.Component {

  state = {
    loading: true,
    schema: defaultSchema,
    name: "New",
    opened: false,
    open: false,
    data: [],
    searchQuery: "",
  }

  _filterData = (obj) => {
    return obj.qTitle.toLowerCase().includes(this.state.searchQuery.toLowerCase());
  }

  handleOnChange = schema => {
    this.setState({ schema });
  };

  handleSubmit = () => {
    const schema = JSON.stringify(this.state.schema);
    const qTitle = this.state.name;
    this.props.adminService.saveQuestionnaire([{ jsonSchema: schema, qTitle }]).then((result) => {
      if (result && result.data) {
        this._loadData();
        this.setState({ open: false, opened: false })
      }
    }).catch((e) => {
      alert(e);
    });
  };

  _openModal = () => {
    this.setState({ 
      open: true,
      schema: defaultSchema,
      name: "New"
    });
  }

  handleCopy = (schema, name) => {
    this.setState({
      open: true,
      schema: JSON.parse(schema),
      name
    })
  }

  _closeModal() {
    this.setState({ 
      open: false, 
      opened: false,
    });
  }

  componentDidMount() {
    this._loadData();
  }
   
  _loadData = () => {
    this.setState({ loading: true })
    this.props.adminService.getAllQuestionnaire().then((result) => {
      if (result) this.setState({ data: result.data , loading: false });
    })
  }

  handleInputChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  }

  render() {
    return <React.Fragment>
      <Modal visible={this.state.open} dialogClassName={'modal-lg'} onClickBackdrop={this._closeModal.bind(this)}>
        <div className="modal-header">
          <button className={"btn btn-md btn-success"} onClick={this.handleSubmit}>Save {this.state.id ? "Changes" : ""}</button>
          <form className="form-inline">
            <div className="form-group">
              <label>Title:</label>
              <input type="text" value={this.state.name} onChange={this.handleInputChange} className="form-control mx-sm-3" />
              <small className="text-muted">
                For internal purpose
              </small>
            </div>
          </form>
          <button className={'btn btn-default'} onClick={this._closeModal.bind(this)}>x</button>
        </div>
        <div className="modal-body" style={{maxHeight: '90vh', overflowY: 'scroll' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <FormBuilder form={this.state.schema} onChange={this.handleOnChange} options={builderOptions}/>
          </div>
        </div>
      </Modal>
      <nav className="navbar navbar-light bg-light">
        <button className={"btn btn-md btn-success"} onClick={this._openModal}>Create</button>
        <div className="form-group">
          <div className="input-group">
            <input
              className="form-control"
              value={this.state.searchQuery}
              onChange={(event) => this.setState({ searchQuery: event.target.value })}
              placeholder="Search by title"
            />
          </div>
        </div>
      </nav>
      {this.state.loading ? <Loader />  : (
      <table className={"table table-hover"}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Created At</th>
            <th>Created By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.filter(this._filterData).map(o => <tr key={o.id}>
            <td>{o.id}</td>
            <td>{o.qTitle}</td>
            <td>{new Date(o.createdAt * 1000).toUTCString()}</td>
            <td>{o.createdBy}</td>
            <td><Actions handleCopy={() => this.handleCopy(o.jsonSchema, o.qTitle)}/></td>
          </tr>)}
        </tbody>
      </table>
      )}
    </React.Fragment>
  }
}

export default withRouter(FormBuilderView);
