import React from 'react'
import Modal from 'react-bootstrap4-modal';
import { withRouter } from 'react-router-dom';
import Loader from '../common/Loader';
import Select from 'react-select';
import { v4 } from 'uuid';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
});


function Actions (props) {
  return <React.Fragment>
    <button className={"btn btn-md btn-warning"} onClick={props.handleEdit}>Edit</button>
  </React.Fragment>
}

function ThresholdEdit (props) {
  return <React.Fragment>
      <td style={{ width: '10%', fontSize: '14px', verticalAlign: 'middle', cursor: 'grab' }}>
        <i className="fa fa-ellipsis-v" aria-hidden="true" style={{ marginRight: '1px'}}></i>
        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
      </td>
      <td style={{ width: '30%'}}>
        <div className="form-group">
          <Select
            defaultValue={null}
            options={props.operators}
            value={props.operators.find(opt => props.operatorId && opt.id === props.operatorId)}
            getOptionLabel={operator => operator.symbol}
            getOptionValue={operator => operator.id}
            onChange={(operator) => {
              props.handleChange(props.index, 'operatorId', operator.id);
            }}
          />
        </div>
      </td>
      <td style={{ width: '20%'}}>
        <div className="form-group">
          <input type="number" value={props.threshold} onChange={(e) => props.handleChange(props.index, 'threshold', parseInt(e.target.value, 10))} className="form-control form-control-md" />
        </div>
      </td>
      <td style={{ width: '20%'}}>
        <div className="form-group">
          <Select
            defaultValue={null}
            options={props.outcomes}
            value={props.outcomes.find(opt => props.outcomeId && opt.id === props.outcomeId)}
            getOptionLabel={outcome => outcome.outcomeName}
            getOptionValue={outcome => outcome.id}
            onChange={(outcome) => {
              props.handleChange(props.index, 'outcomeId', outcome.id);
            }}
          />
        </div>
      </td>
      <td style={{ width: '10%'}}>
        <div className="form-group">
          <button className={'btn btn-sm btn-danger'} onClick={() => props.handleRemoveThreshold(props.index)}>x</button>
        </div>
      </td>
  </React.Fragment>
}

class ScoreLogic extends React.Component {
  state = {
    loading: true,
    submitting: false,
    data: [],
    searchQuery: "",
    id: null,
    value: "",
    new: true,
    thresholds: [],
    operators: [],
    outcomes: [],
  }

  handleInputChange = logic => {
    this.setState({ logic });
  };

  componentDidMount() {
    this._loadData();
  }


  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const thresholds = reorder(
      this.state.thresholds,
      result.source.index,
      result.destination.index
    );

    this.setState({
      thresholds
    });
  }

  _loadData = () => {
    this.setState({ loading: true });
    Promise.all([
      this.props.adminService.getScoreLogic(),
      this.props.adminService.getOperators(),
      this.props.adminService.getOutcomes(),
    ]).then(([scorelogickz, ops, out]) => {
      if (scorelogickz && ops) {
        this.setState({ 
          data: scorelogickz.data,
          operators: ops.data,
          outcomes: out.data,
          loading: false
        });
      }
    })
  }

  handleSubmit = () => {
    this.setState({ submitting: true })
    const request = { 
      logic: this.state.logic
    }
    if(this.state.id) request.id = this.state.id
    this.props.adminService.createScoreLogic([request]).then((result) => {
      if (result && result.data[0]) {
        const thresholds = this.state.thresholds.map(({
          outcomeId,
          operatorId,
          threshold,
        }, i) => ({
          outcomeId,
          operatorId,
          threshold,
          precedence: i,
          scoreLogicId: result.data[0].id
        }));
        this.props.adminService.createWeightedPts(thresholds).then((result2) => {
          if (result2 && result2.data) {
            this._loadData();
            this.setState({ open: false, opened: false, submitting: false })
          }
        }).catch((e) => {
          alert(e);
        });
      }
    }).catch((e) => {
      alert(e);
    });
  };

  _filterData = (obj) => {
    return obj.logic.toLowerCase().includes(this.state.searchQuery.toLowerCase())
  }

  _closeModal() {
    this.setState({ 
      open: false, 
      opened: false,
      new: true,
    });
  }

  _openModal = () => {
    this.setState({ 
      open: true,
      id: null,
      logic: "",
    });
  }

  handleChange = (index, prop, value) => {
    const thresholds = this.state.thresholds;
    const tbe = thresholds.find(t => t.index === index);
    tbe[prop] = value;
    this.setState({ 
      thresholds,
    })
  }

  handleRemoveThreshold = (index) => {
    const thresholds = this.state.thresholds.filter(t => t.index !== index);
    this.setState({ 
      thresholds: thresholds,
    })
  }

  _addThreshold = () => {
    const thresholds = this.state.thresholds;
    thresholds.push({
      index: v4(),
      outcomeId: null,
      operatorId: null,
      threshold: 0
    })
    this.setState({
      thresholds,
    });
  }

  handleEdit = ({id, logic}) => {
    let thresholds = [];
    this.props.adminService.getWeightedPts(id).then((result) => {
      if(result && result.data) {
        console.log(result.data);
        thresholds = result.data.map(({ outcomeId, operatorId, threshold, precedence }) => ({
          outcomeId, operatorId, threshold, precedence, index: v4()
        }))
      }
    }).catch((e) => {
      console.log(e)
    }).finally(() => {
      this.setState({
        open: true,
        id,
        logic,
        thresholds,
        new: false
      })
    })
  }

  render() {
    return <React.Fragment>
      <Modal visible={this.state.open} dialogClassName={'modal-md'} onClickBackdrop={this._closeModal.bind(this)}>
        <div className="modal-header">
          <button className={"btn btn-md btn-success"} disabled={this.state.submitting} onClick={this.handleSubmit}>{this.state.id ? "Update" : "Save"}</button>
          {this.state.submitting ? <Loader /> : null}
          <button className={'btn btn-default'} onClick={this._closeModal.bind(this)}>x</button>
        </div>
        <div className="modal-body">
          <div className={'row'}>
            <div className={'col-sm-12'}>
                <div className="input-group input-group-sm" style={{margin: '10px'}}>
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">Title</span>
                  </div>
                  <input type="text" value={this.state.logic} onChange={(e) => this.handleInputChange(e.target.value)} className="form-control" disabled={!this.state.new} />
                </div>
            </div>
            <p className="text-muted">Thresholds are executed in top to bottom order</p>
            <table className={"table table-hover"}>
              <thead>
                <tr>
                  <th style={{ width : '10%' }}></th>
                  <th style={{ width : '30%' }}>Operator</th>
                  <th style={{ width : '20%' }}>Threshold</th>
                  <th style={{ width : '30%' }}>Outcome</th>
                  <th style={{ width : '10%' }}>Delete</th>
                </tr>
              </thead>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <tbody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.thresholds.map((weighedPts, index) => (
                        <Draggable key={weighedPts.index} draggableId={weighedPts.index} index={index}>
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <ThresholdEdit
                                index={weighedPts.index}
                                handleChange={this.handleChange}
                                threshold={weighedPts.threshold}
                                operatorId={weighedPts.operatorId}
                                outcomeId={weighedPts.outcomeId}
                                operators={this.state.operators}
                                outcomes={this.state.outcomes}
                                handleRemoveThreshold={this.handleRemoveThreshold}
                              />
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
          </div>
          <div cla
          isDisabled={!!this.state.id}ssName={'row'}>
            <button className={'btn btn-success'} onClick={this._addThreshold}>+ Add Thresholds</button>
          </div>
        </div>
      </Modal>
      <nav className="navbar navbar-light bg-light">
        <button className={"btn btn-md btn-success"} onClick={this._openModal}>Create</button>
        <div className="form-group">
          <div className="input-group">
            <input
              className="form-control"
              value={this.state.searchQuery}
              onChange={(event) => this.setState({ searchQuery: event.target.value })}
              placeholder="Search by name or title"
            />
          </div>
        </div>
      </nav>
      {this.state.loading ? <Loader /> :
      (<table className={"table table-hover"}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.filter(this._filterData).map(o => <tr key={o.id}>
            <td>{o.id}</td>
            <td>{o.logic}</td>
            <td><Actions handleEdit={() => this.handleEdit(o)}/></td>
          </tr>)}
        </tbody>
      </table>)}
      </React.Fragment>
  }
}

export default withRouter(ScoreLogic);