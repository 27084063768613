import axios from 'axios'
import config from '../config'

const lableProps = ['label', 'customMessage']

function forEachNested(O, f, cur){
    O = [ O ];
    while (O.length)
    if(
        !f( cur = O.pop() ) &&
        cur instanceof Object &&
        [Object, Array].includes(cur.constructor)
    ) O.push.apply(O, Object.values(cur));
}

const CommonService = (token) => {

    const instance = axios.create({
        baseURL: config.apiUrl,
        timeout: 10000,
        headers: { 
            Authorization: token,
            'Content-type' : 'application/json'
        }
    });

    const removeParameterByName = (name, url) => {
        if (!url) url = window.location.href;
        name = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
        if (results && results[0]) {
          url = url.replace(results[0], results[0][0] === '?' ? "?" : "");
        }
        return url;
    }

    return {
        fetchQuestionnaire: async (scoreQMapId) => {
            try {
                const response = await instance.post('/api/v1/user/questionnaire/create', {
                    scoreQMapId
                });
                return response;
            } catch (error) {
                console.error(error);
            }
        },
        fetchTranslations: async (code, lang) => {
            try {
                const response = await instance.post('/api/v1/user/translation', { code, lang });
                const translationObj = {};
                response.data.forEach(element => {
                    const langString = element[lang];
                    translationObj[element.code] = langString;
                });
                return translationObj;
            } catch (error) {
                console.error(error);
            }
        },
        submitQuestionnaire: async (scoreQMapId, data) => {
            try {
                const response = await instance.post(`/api/v1/user/questionnaire/submit?scoreQMapId=${scoreQMapId}`, { data });
                return response;
            } catch (error) {
                console.error(error);
            }
        },
        removeSensitiveParams() {
            if (!window.location.search) return;
                const sensitiveParams = ['o'];
            let search = window.location.search;
            while (sensitiveParams.length) {
              search = removeParameterByName(sensitiveParams.pop(), search);
            }
            window.history.replaceState(null, null, window.location.pathname + search + window.location.hash);
        },
        extractCode(schema) {
            const results = new Set();
            forEachNested(schema,(currentValue) => {
                if(currentValue && currentValue.constructor === Object) {
                    Object.keys(currentValue).forEach(key => {
                        if(Object.hasOwnProperty.call(currentValue, key) 
                        && lableProps.includes(key) 
                        && currentValue[key]
                        && typeof currentValue[key] === 'string') {
                            results.add(currentValue[key]);
                        }
                    })
                }
            })
            return Array.from(results);
        }
    }
};


export default CommonService;