import React from 'react'
import { Form } from 'react-formio'
import '../formio.full.min.css'
import store from 'store2'
import config from '../config'
import CommonSevice from './../services/common-service'

const submissionKey = "submissionData"
class FormContainer extends React.Component {

  state = {
    form: {},
    options: {
      language: this.props.lang,
      i18n: {
        [this.props.lang]: this.props.translations
      }
    },
    submission: {},
    submitting: false
  }

  componentDidMount() {
    let data;
    try {
      data = JSON.parse(store.get(`${submissionKey}_${this.props.scoreQMapId}`) || {});
      Object.keys(data).forEach(k => {
        if(data[k] === "") delete data[k]
      })
    } catch (error) {
    }
    this.setForm(this.props.form, { data });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.form !== this.props.form) {
      this.setForm(this.props.form)
    }
  }
  
  setForm(form, submission = {}) {
    try {
      this.setState({ form, submission });
    } catch (e) {
      alert('Invalid Form');
    }
  }

  submitHandler = (data) => {
    if (data && data.data && Object.keys(data.data).length > 1 && !this.state.submitting) {
      this.setState({submitting : true }, () => {
        const token = store.get(config.tokenKey);
        const commonService = CommonSevice(token);
        const search = window.location.search;
        const onCompleteUrl = new URLSearchParams(search).get("oncompleteurl");
        commonService.submitQuestionnaire(this.props.scoreQMapId, data.data).then((response) => {
          if(response && response.data && response.data.id && response.data.outcomeId) {
            store.remove(config.tokenKey);
            store.remove(`${submissionKey}_${this.props.scoreQMapId}`);
            if(onCompleteUrl){
              window.location = `${onCompleteUrl}?id=${response.data.id}&success=${response.data.outcomeId === 1}`
            }else{
              window.location = `${config.redirectUrl}?id=${response.data.id}&success=${response.data.outcomeId === 1}`;    
            }
          } else {
            alert('Please check your internet connection and try refreshing the page.')
            window.location.reload();
          }
          return true;
        })
      })
    }
    return false;
  }

  onChange = (e) => {
    const { data } = e;
    store.set(`${submissionKey}_${this.props.scoreQMapId}`, JSON.stringify(data));
  }

  render() {
    return (<React.Fragment>
        <Form form={this.state.form} options={this.state.options} submission={this.state.submission} onSubmit={this.submitHandler} onChange={this.onChange} />
    </React.Fragment>)
  }
}

export default FormContainer;
