import React from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";
import FormView from './FormView';

class UserView extends React.Component {

  componentDidMount() {
  }

  render() {
    return <Switch>
        <Route path={`/`}  component={FormView}>
        </Route>
    </Switch>
  }
}

export default UserView;
