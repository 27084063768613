import React from 'react'
import Modal from 'react-bootstrap4-modal';
import { withRouter } from 'react-router-dom';
import Loader from '../common/Loader';
import Select from 'react-select';
import config from '../config';

function Actions (props) {
  return <React.Fragment>
    <button className={"btn btn-md btn-warning"} onClick={props.handleEdit}>Edit</button>
    <button className={"btn btn-md btn-primary"} onClick={props.handlePreview}>Preview</button>
    <button className={"btn btn-md btn-danger"} onClick={props.handleDelete}>x</button>
  </React.Fragment>
}

class MapLogicView extends React.Component {

  state = {
    loading: true,
    form: {},
    data: [],
    schemas: [],
    logic: [],
    searchQuery: "",
    qSchemaId: "",
    scoreLogicId: "",
    title: "",
    slug: "",
    id: ""
  }

  handleInput = event => {
    this.setState({ form: event.target.value });
  };

  componentDidMount() {
    this._loadData();
  }

  _loadData = () => {
    this.setState({ loading: true });
    Promise.all([
      this.props.adminService.getScoreQMap(),
      this.props.adminService.getAllQuestionnaire(),
      this.props.adminService.getScoreLogic()
    ]).then(([scoreQMaps, schemas, logic]) => {
      if (scoreQMaps) {
        scoreQMaps.data.forEach(scoreQMap => {
          scoreQMap.schema = schemas.data.find(({id}) => id === scoreQMap.qSchemaId)
          scoreQMap.logic = logic.data.find(({id}) => id === scoreQMap.scoreLogicId)
        });
        this.setState({ data: scoreQMaps.data, loading: false, schemas: schemas.data, logic: logic.data  });
      }
    })
  }

  handleSubmit = () => {
    const existsAndMapChanged = this.state.data.find(o => this.state.id === o.id 
      && (o.qSchemaId !== this.state.qSchemaId || o.scoreLogicId !== this.state.scoreLogicId));
    if (existsAndMapChanged) {
      alert(`Cannot change existing mapping`);
      return;
    }

    const exists = this.state.data.find(o => o.qSchemaId === this.state.qSchemaId && o.scoreLogicId === this.state.scoreLogicId);
    if (exists && !this.state.id) {
      alert(`This mapping alread exists as ${exists.slug}`);
      return;
    }
    this.props.adminService.createScoreQMap([{
      qSchemaId: this.state.qSchemaId,
      scoreLogicId: this.state.scoreLogicId,
      title: this.state.title,
      slug: this.state.slug,
      id: this.state.id
    }]).then((result) => {
      if (result && result.data) {
        this._loadData();
        this.setState({ open: false, opened: false })
      }
    }).catch((e) => {
      alert(e);
    });
  };

  handleEdit = (o) => {
    this.setState({
      open: true,
      qSchemaId: o.qSchemaId,
      scoreLogicId: o.scoreLogicId,
      title: o.title,
      slug: o.slug,
      id: o.id
    })
  }

  handleDelete = (o) => {
    const cnf = window.confirm('Are you sure you want to delete this mapping?');
    if (cnf) {
      this.props.adminService.deleteScoreQMap(o.id).then((result) => {
        if (result && result.data) {
          this._loadData();
          this.setState({ open: false, opened: false })
        }
      }).catch((e) => {
        alert(e);
      });
    }
  }

  handlePreview(o) {
    this.props.adminService.getJWT({}).then((result) => {
      if (result && result.data) {
        const { token } = result.data;
        window.open(config.appUrl + `?q=${o.id}&l=en&o=`+ token)
      }
    }).catch((e) => {
      alert(e);
    });
  }

  _filterData = (obj) => {
    return obj.title.toLowerCase().includes(this.state.searchQuery.toLowerCase())
      || obj.slug.toLowerCase().includes(this.state.searchQuery.toLowerCase());
  }

  _closeModal() {
    this.setState({ 
      open: false, 
      opened: false,
    });
  }

  _openModal = () => {
    this.setState({ 
      open: true,
      qSchemaId: "",
      scoreLogicId: "",
      title: "",
      slug: "",
      id: ""
    });
  }

  render() {
    return <React.Fragment>
      <Modal visible={this.state.open} dialogClassName={'modal-lg'} onClickBackdrop={this._closeModal.bind(this)}>
        <div className="modal-header">
          <button className={"btn btn-md btn-success"} onClick={this.handleSubmit}>{this.state.id ? "Update" : "Save"}</button>
          <button className={'btn btn-default'} onClick={this._closeModal.bind(this)}>x</button>
        </div>
        <div className="modal-body">
          <div className={'row'}>
            <div className={'col-sm-6'}>
              <div className="form-group">
                <label>Title (Title that appears on the questionnaire)</label>
                <div className="input-group">
                  <input
                    className="form-control"
                    value={this.state.title}
                    onChange={(event) => this.setState({ title: event.target.value })}
                    placeholder="Enter Title"
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Questionnaire</label>
                <Select
                  defaultValue={null}
                  options={this.state.schemas}
                  isClearable={true}
                  value={this.state.schemas.find(opt => this.state.qSchemaId && opt.id === this.state.qSchemaId)}
                  getOptionLabel={group => group.qTitle}
                  getOptionValue={group => group.id}
                  onChange={(schema) => {
                    this.setState({ qSchemaId: schema.id });
                  }}
                  isDisabled={!!this.state.id}
                />
              </div>
            </div>
            <div className={'col-sm-6'}>
              <div className="form-group">
                <label>Name (For Seaching/Identification)</label>
                <div className="input-group">
                  <input
                    className="form-control"
                    value={this.state.slug}
                    onChange={(event) => this.setState({ slug: event.target.value })}
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Scoring Logic to Apply</label>
                <Select
                  defaultValue={null}
                  options={this.state.logic}
                  isClearable={true}
                  value={this.state.logic.find(opt => this.state.scoreLogicId && opt.id === this.state.scoreLogicId)}
                  getOptionLabel={logic => logic.logic}
                  getOptionValue={logic => logic.id}
                  onChange={(logic) => {
                    this.setState({ scoreLogicId: logic.id });
                  }}
                  isDisabled={!!this.state.id}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <nav className="navbar navbar-light bg-light">
        <button className={"btn btn-md btn-success"} onClick={this._openModal}>Create</button>
        <div className="form-group">
          <div className="input-group">
            <input
              className="form-control"
              value={this.state.searchQuery}
              onChange={(event) => this.setState({ searchQuery: event.target.value })}
              placeholder="Search by name or title"
            />
          </div>
        </div>
      </nav>
      {this.state.loading ? <Loader /> :
      (<table className={"table table-hover"}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Schema</th>
            <th>Score Logic</th>
            <th>Title</th>
            <th>Created At</th>
            <th>Created By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.filter(this._filterData).map(o => <tr key={o.id}>
            <td>{o.id}</td>
            <td>{o.slug}</td>
            <td>{o.schema.qTitle}</td>
            <td>{o.logic.logic}</td>  
            <td>{o.title}</td>  
            <td>{new Date(o.createdAt * 1000).toUTCString()}</td>
            <td>{o.createdBy}</td>  
            <td><Actions handleEdit={() => this.handleEdit(o)} handleDelete={() => this.handleDelete(o)} handlePreview={() => this.handlePreview(o)}/></td>
          </tr>)}
        </tbody>
      </table>)}
      </React.Fragment>
  }
}

export default withRouter(MapLogicView);
