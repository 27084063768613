export const getLangName = (lang) => {
    return lang || 'en';
};

export const emptyObj = () => Object.create(null)

export const isNullOrUndefined = (val) => val === undefined || val === null;

const commonUtil = {
    getLangName,
    emptyObj,
    isNullOrUndefined
}

export default commonUtil;