import React from 'react'
import './App.css'
// import './bootstrap.min.css'
import AdminView from './admin/AdminView'
import config from './config'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import UserView from './user/UserView'

class App extends React.Component {

  componentDidMount() {
    console.log(config)
  }
  
  render() {
    return <Router>
        <Switch>
          <Route path="/">
            {config.admin === 'true' ? <AdminView /> : <UserView />}
          </Route>
        </Switch>
    </Router>
  }
}

export default App;
