import React from 'react'
import Modal from 'react-bootstrap4-modal';
import { withRouter } from 'react-router-dom';
import Loader from '../common/Loader';
import { emptyObj, isNullOrUndefined } from '../services/common-util'

function Actions (props) {
  return <React.Fragment>
    <button className={"btn btn-md btn-warning"} onClick={props.handleEdit}>Edit</button>
  </React.Fragment>
}

class TranslationTableView extends React.Component {

  state = {
    loading: true,
    opened: false,
    open: false,
    new: false,
    data: [],
    languages: [],
    edit: [],
    searchQuery: "",
  }

  _filterData = (obj) => {
    return obj[0] && obj[0].i18nCode.code.toLowerCase().includes(this.state.searchQuery.toLowerCase());
  }

  componentDidMount() {
    this._loadData();
  }

  _loadData = () => {
    this.setState({ loading: true });
    Promise.all([
      this.props.adminService.getAllLanguages(),
      this.props.adminService.getAllTranslations()
    ]).then(([languageResult, translationResult]) => {
      if (languageResult && translationResult) {
        const translations = translationResult.data ?  translationResult.data : [];
        const languages = languageResult.data ?  languageResult.data : [];
        // Group by i18nCode ID
        const data = Object.values(translations.reduce((acc, curr) => {
          if(!acc[curr.i18nCodeId]) acc[curr.i18nCodeId] = [];
          acc[curr.i18nCodeId].push(curr);
          return acc;
        }, emptyObj()));
        this.setState({ data, languages, loading: false});
      }
    })
  }

  handleSubmit = () => {
    if(this.state.edit.some(o => !o.code)) {
      alert('Invalid input')
      return;
    }
    this.props.adminService.createTranslations(this.state.edit).then((result) => {
      if (result && result.data) {
        this._loadData();
        this.setState({ open: false, opened: false })
      }
    }).catch((e) => {
      alert(e);
    });
  };

  handleInputChange = (code, language, translation) => {
    let changes = this.state.edit;
    const existing = changes.find(c => c.code === code && c.language === language);
    if (!isNullOrUndefined(code) && !language && !translation) changes = changes.map( c => { c.code = code; return c } )
    else if (existing) existing.translation = translation
    this.setState({
      edit: changes
    })
  }

  handleEdit = (codeTranslations) => {
    const edit = this.state.languages.map(l => ({
      code: codeTranslations[0].i18nCode.code,
      language: l.value,
      translation: codeTranslations.find(e => e.languageId === l.id)?.translation,
    }))
    this.setState({
      open: true,
      edit
    })
  }

  _closeModal() {
    this.setState({ 
      open: false, 
      opened: false,
      new: false,
    });
  }

  _openModal = () => {
    this.setState({ 
      open: true,
      edit: this.state.languages.map(l => ({ code: '', language: l.value, translation: '' })),
      new: true
    });
  }
   
  render() {
    return <React.Fragment>
      <Modal visible={this.state.open} dialogClassName={'modal-lg'} onClickBackdrop={this._closeModal.bind(this)}>
        <div className="modal-header">
          <button className={"btn btn-md btn-success"} onClick={this.handleSubmit}>{"Save"}</button>
          <button className={'btn btn-default'} onClick={this._closeModal.bind(this)}>x</button>
        </div>
        <div className="modal-body">
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <div className={'col-sm-12'}>
              <div className="input-group input-group-sm" style={{margin: '10px'}}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Code</span>
                </div>
                <input type="text" value={this.state.edit && this.state.edit[0] ? this.state.edit[0].code : ''}  onChange={(e) => this.handleInputChange(e.target.value)} disabled={!this.state.new} className="form-control" />
              </div>
              {
                this.state.languages.map(l => {
                  return <div key={l.id} className="input-group input-group-sm" style={{margin: '10px'}}>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="inputGroup-sizing-sm">{l.value}</span>
                    </div>
                    <input type="text" value={this.state.edit.find(e => e.language === l.value)?.translation} onChange={(e) => this.handleInputChange(this.state.edit[0].code, l.value, e.target.value)} className="form-control" />
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </Modal>
      <nav className="navbar navbar-light bg-light">
        <button className={"btn btn-md btn-success"} onClick={this._openModal}>Create</button>
        <div className="form-group">
          <div className="input-group">
            <input
              className="form-control"
              value={this.state.searchQuery}
              onChange={(event) => this.setState({ searchQuery: event.target.value })}
              placeholder="Search by code"
            />
          </div>
        </div>
      </nav>
      {this.state.loading ? <Loader /> :
      (<table className={"table table-hover"} style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ width: '5%'}}>ID</th>
            <th>code</th>
            {this.state.languages.map(k =><th key={k.id}>{k.value}</th>)}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.filter(this._filterData).map((o, i) => <tr key={o[0].i18nCodeId}>
            <td>{i+1}</td>
            <td>{o[0].i18nCode.code}</td>
            {this.state.languages.map(k => {
              const trsnl = o.find(t => t.languageId === k.id)?.translation;
              return <td key={k.id} style={{ overflow: 'hidden',
          textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'}} data-toggle="tooltip" title={trsnl}>{trsnl}</td>
              }
            )}
            <td><Actions handleEdit={() => this.handleEdit(o)}/></td>
          </tr>)}
        </tbody>
      </table>)}
    </React.Fragment>
  }
}

export default withRouter(TranslationTableView);
