import axios from 'axios'
import config from '../config'

const AdminService = (token) => {

    const instance = axios.create({
        baseURL: config.apiUrl,
        timeout: 10000,
        headers: { 
            'x-auth-admin': token,
            'Content-type' : 'application/json'
        }
    });

    const post = async (url, body) => {
        try {
            const response = await instance.post(url, body);
            return response;
        } catch (error) {
            console.error(error);
            alert('Something went wrong');
        }
    };
    const get = async (url) => {
        try {
            const response = await instance.get(url);
            return response;
        } catch (error) {
            console.error(error);
            alert('Something went wrong');
        }
    };
    const del = async (url) => {
        try {
            const response = await instance.delete(url);
            return response;
        } catch (error) {
            console.error(error);
            alert('Something went wrong');
        }
    }
    const adminPath = '/api/v1/admin/'
    return {
        getAllQuestionnaire: () => get(`${adminPath}q-schema`),
        getJWT: (body) => post(`${adminPath}jwt`, body),
        saveQuestionnaire: (body) => post(`${adminPath}q-schema`, body),
        createTranslations: (body) => post(`${adminPath}translation`, body),
        getAllTranslations: () => get(`${adminPath}translation`),
        getAllLanguages: () => get(`${adminPath}language`),
        createAllLanguages: (body) => post(`${adminPath}language`, body),
        createScoreQMap: (body) => post(`${adminPath}score-q-map`, body),
        deleteScoreQMap: (id) => del(`${adminPath}score-q-map?id=` + id ),
        deleteTrigger: (id) => del(`${adminPath}triggers?id=` + id ),
        getScoreQMap: () => get(`${adminPath}score-q-map`),
        getScoreLogic: () => get(`${adminPath}score-logic`),
        createScoreLogic: (body) => post(`${adminPath}score-logic`, body),
        getOperators: () => get(`${adminPath}operator`),
        getWeightedPts: (scoreLogicId) => get(`${adminPath}weighed-pts?scoreLogicId=${scoreLogicId}`),
        createWeightedPts: (body) => post(`${adminPath}weighed-pts`, body),
        getTriggers: () => get(`${adminPath}triggers`),
        getOutcomes: () => get(`${adminPath}outcome`),
        getActions: () => get(`${adminPath}action`),
        createTriggers: (body) => post(`${adminPath}triggers`, body),
    }
};


export default AdminService